/**
 * Created by andrey on 04.06.2024.
 */

export default {
    loadSdk: function (src, onSuccess) {
        var attempt = 3;

        var script = document.createElement("script");
        script.setAttribute("crossorigin", "anonymous");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", src);

        var loadTimeout;

        var onFailureCallback = function () {
            clearTimeout(loadTimeout);
            script.onload = undefined;
            script.onerror = undefined;

            if (attempt > 1) {
                if (document.body.contains(script)) {
                    document.body.removeChild(script);
                }
                attempt--;
                loadSdk(path, options);
            } else {
                onFailure();
            }
        };

        script.onload = function () {
            clearTimeout(loadTimeout);

            onSuccess();
        };
        script.onerror = onFailureCallback;
        loadTimeout = setTimeout(onFailureCallback, 30000);

        document.body.appendChild(script);
    }
}

