<template>
    <Carousel :class="hasMaxWidth ? 'carousel-max-width' : ''" :items-to-show="this.portalData.games[this.$route.params.game].screenshots_portrait || !this.isMobile ? (screenWidth <= 384 ? 3 : 4) : 1" :wrapAround="false" ref="carousel">
        <Slide class="carousel-inner" v-for="slide in (gameVideoUrl ? screenshotsCount + 1 : screenshotsCount)" :key="slide" >
            <div v-if="gameVideoUrl && slide === 1" class="d-flex align-items-center justify-content-center carousel__item" style="height: 100%;" @click="openVideoPreview">
                <div class="position-absolute play-icon-wrapper">
                    <img id="play-icon" class="play-icon" src="">
                </div>
                <div class="video-wrapper">
                    <div class="video" :style="{ 'background-image': 'url(' + videoThumbnailUrl + ')' }"></div>
                </div>
            </div>
            <img v-else class="carousel__item" :src="gameScreenshot(slide - 1, true)" @click="openScreenshotPreview(slide - 1)">
        </Slide>

        <template #addons>
            <Navigation/>
        </template>
    </Carousel>

    <div ref="modalId" v-if="modalImage" class="modal d-block" @click="modalImage=false" tabindex="-1"
         @keydown.left="carouselPrev" @keydown.right="carouselNext" @keydown.esc="modalImage=false">
        <div v-if="modalImage" class="overlay" aria-busy="false">
            <div class="background" style="backdrop-filter: blur(2px);"></div>
        </div>

        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" @click="modalImage=false"></button>
                </div>
                <div class="modal-body">
                    <img :src="modalImage" class="imagepreview">
                </div>
                <button v-show="$refs.carousel.data.currentSlide.value > 1" type="button" class="carousel__prev" @click="carouselPrev" aria-label="Navigate to previous slide">
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                        <title>arrowLeft</title>
                        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                    </svg>
                </button>
                <button v-show="$refs.carousel.data.currentSlide.value < screenshotsCount" type="button" class="carousel__next" @click="carouselNext" aria-label="Navigate to next slide">
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                        <title>arrowRight</title>
                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>

    <div ref="modalVideo" v-if="modalVideo" class="modal d-block" tabindex="-1" @keydown.esc="modalVideo=false">
        <div v-if="modalVideo" class="overlay" aria-busy="false">
            <div class="background" style="backdrop-filter: blur(2px);"></div>
        </div>

        <div class="modal-dialog modal-dialog-centered" style="width: 1000px;">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" @click="modalVideo=false"></button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                    <AnimatedPlaceholder v-show="!videoLoaded" :width="videoWidth" :height="videoHeight"/>
                    <YouTube v-show="videoLoaded" id="game-video-container" class="video" :width="videoWidth" :height="videoHeight" :src="gameVideoUrl" @ready="onVideoReady" ref="youtube"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel';
    import 'vue3-carousel/dist/carousel.css';
    import YouTube from 'vue3-youtube'

    import portalData from './../../portal.json';
    import AnimatedPlaceholder from "@/components/animatedplaceholder.vue";

    export default {
        name: 'ScreenshotsCarousel',
        props: {
            hasMaxWidth: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        components: {
            AnimatedPlaceholder,
            Carousel,
            Navigation,
            Slide,
            Pagination,
            YouTube
        },
        data() {
            return {
                portalData: portalData,
                screenWidth: 1000,
                modalImage: false,
                modalVideo: false,
                videoLoaded: false,
                videoWidth: "960px",
                videoHeight: "540px"
            }
        },
        mounted() {
            this.$refs.carousel.slideTo(0);
            var videoPlayIcon = document.getElementById("play-icon");
            var images = require.context('./../../assets', false, /\.png$/);
            videoPlayIcon.src = images('./play.png');

            window.addEventListener("resize", this.onResize);

            setTimeout(function () {
                this.onResize();
            }.bind(this), 500);
        },
        unmounted() {
            window.removeEventListener("resize", this.onResize);
        },
        computed: {
            lang() {
                return this.$route.params.lang || "en";
            },
            isMobile() {
                return this.screenWidth <= 760;
            },
            screenshotLanguages() {
                return Object.keys(this.portalData.games[this.$route.params.game].screenshots).filter(lang => {
                    var images = this.portalData.games[this.$route.params.game].screenshots[lang];
                    return images && images.length > 0;
                });
            },
            screenshotsCount() {
                var result;
                if (this.isMobile) {
                    result = this.portalData.games[this.$route.params.game].screenshots_portrait && this.portalData.games[this.$route.params.game].screenshots_portrait['en'].length;
                }

                return result ? result : this.portalData.games[this.$route.params.game].screenshots['en'].length;
            },
            gameVideoUrl() {
                return this.portalData.games[this.$route.params.game].video;
            },
            videoThumbnailUrl() {
                var videoId = undefined;
                var index = this.gameVideoUrl.lastIndexOf("=");
                if (index === -1) {
                    index = this.gameVideoUrl.lastIndexOf("/")
                }
                videoId = this.gameVideoUrl.slice(index + 1);
                return "http://img.youtube.com/vi/" + videoId + "/0.jpg";
            }
        },
        methods: {
            gameScreenshot(index, minimal) {
                index = index - 1;
                var takePortrait = this.isMobile && this.portalData.games[this.$route.params.game].screenshots_portrait && this.portalData.games[this.$route.params.game].screenshots_portrait['en'].length;
                if (index >= this.portalData.games[this.$route.params.game].screenshots['en'].length) {
                    takePortrait = true;
                    index -= this.portalData.games[this.$route.params.game].screenshots['en'].length;
                }

                var portraitImages, images;
                if (minimal) {
                    portraitImages = require.context('./../../assets/screenshots_portrait/minimal', false, /\.jpg$/);
                    images = require.context('./../../assets/screenshots/minimal', false, /\.jpg$/);
                } else {
                    portraitImages = require.context('./../../assets/screenshots_portrait', false, /\.jpg$/);
                    images = require.context('./../../assets/screenshots', false, /\.jpg$/);
                }

                var lang = this.screenshotLanguages.indexOf(this.lang + '') === -1 ? 'en' : this.lang;
                if (takePortrait && this.portalData.games[this.$route.params.game].screenshots_portrait) {
                    return portraitImages('./' + this.$route.params.game + '_' + index + '_' + lang + '.jpg');
                } else {
                    return images('./' + this.$route.params.game + '_' + index + '_' + lang + '.jpg');
                }
            },
            onResize() {
                this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                this.videoWidth = Math.min(960, this.screenWidth * 0.9) + "px";
                this.videoHeight = (Math.min(960, this.screenWidth * 0.9) * 540/960) + "px";
            },
            carouselNext(event) {
                event.preventDefault();
                event.stopPropagation();
                var currentIndex = this.$refs.carousel.data.currentSlide.value;
                this.$refs.carousel.next();
                if (currentIndex === this.$refs.carousel.data.currentSlide.value) {
                    this.$refs.carousel.data.currentSlide.value++;
                }
                this.modalImage = this.gameScreenshot(this.$refs.carousel.data.currentSlide.value);
            },
            carouselPrev(event) {
                event.preventDefault();
                event.stopPropagation();
                this.$refs.carousel.prev();
                this.modalImage = this.gameScreenshot(this.$refs.carousel.data.currentSlide.value);
            },
            openScreenshotPreview(index) {
                this.$refs.carousel.data.currentSlide.value = index;
                this.modalImage = this.gameScreenshot(index);
                setTimeout(function () {
                    this.$refs.modalId.focus();
                }.bind(this), 100);
            },
            onVideoReady() {
                this.videoLoaded = true;
            },
            openVideoPreview() {
                this.modalVideo = true;
                setTimeout(function () {
                    if (!this.$refs.youtube.initiated) {
                        this.$refs.youtube.initPlayer();
                    }
                    if (this.screenWidth < 1000) {
                        // this.$refs.youtube.$el.styles.width = this.screenWidth * 0.9;
                    }
                    this.$refs.modalVideo.focus();
                }.bind(this), 500);
            }
        }
    }
</script>
<style>
    .carousel-inner > img {
        margin: 10px;
        border-radius: 16px;
        width: 96%;
    }

    .carousel-inner > div {
        width: 96%;
        margin: 10px;
    }

    .carousel-inner > div > img {
        border-radius: 16px;
        width: 100%;
    }

    .carousel-inner:hover {
        cursor: pointer;
        transform: scale(1.03);
    }
    .carousel-inner:active {
        opacity: 0.8;
    }

    .carousel-max-width {
        max-width: 980px;
    }

    .carousel {
        width: 100%;
        margin: 0 auto;
    }

    .carousel__prev, .carousel__next {
        background-color: #00b8f4;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 36px;
        padding: 0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border: 0;
        cursor: pointer;
    }
    .carousel__prev--in-active,
    .carousel__next--in-active {
        display: none;
    }

    .carousel__prev {
        left: 8px;
    }
    .carousel__next {
        right: 8px;
    }

    .video-wrapper {
        width: 100%;
        height: 100%;
    }

    .video {
        margin: 10px 0;
        height: 89%;
        border-radius: 16px;
        background-position: center;
        background-size: cover;
    }

    .play-icon-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .play-icon {
        top: auto;
        height: 30%;
    }

    .modal-content {
        width: unset !important;
    }

    .modal-dialog {
        z-index: 1101!important;
        max-width: 85vw!important;
        justify-content: center;
    }

    .modal-body {
        padding: 1rem 2rem;
    }

    .overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: auto;
        z-index: 1100;
    }

    .background {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        background: #5f5f5f;
        opacity: 0.8;
    }

    @media (max-width: 994px) {
        .modal-dialog {
            margin: 0!important;
            max-width: 100%!important;
            max-height: 100%!important;
        }

        .modal-content {
            width: 100% !important;
        }

        .modal-body {
            display: flex;
            justify-content: center;
        }

        .imagepreview {
            max-width: 90vw;
            max-height: 90vh;
        }
    }

</style>