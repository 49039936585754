<template>
    <nav class="navbar fixed-top navbar-expand navbar-light bg-light">
        <div class="container-fluid">
            <img alt="cleverapps" class="navbar-brand" :src="require(isMobile ? './../../assets/logo_mobile.png' : './../../assets/logo.png')" @click="gotoGames()">
            <div v-if="this.$route.name === 'Games'" class="input-group search d-flex align-items-center">
                <input class="form-control search-input" type="search" :value="this.$route.query.sq || ''" @input="onChangeSearch" id="example-search-input">
                <div class="input-icon">
                    <img alt="search" class="search-icon"
                         :src="require('./../../assets/search.png')">
                </div>
            </div>
            <ul class="navbar-nav">
                <li class="nav-item navbar-item dropdown">
                    <div class="nav-link dropdown-toggle" href="/" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <img alt="lang" class="nav-icon" :src="require('./../../assets/lang.png')">
                        {{isMobile ? "" : currentLanguageString}}
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li v-for="language in languages" class="nav-item">
                            <a class="dropdown-item" :class="{'active-lang':isActiveLanguage(language.code)}" @click="setLanguage(language.code)">{{language.title}}</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item navbar-item dropdown">
                    <div class="nav-link d-flex align-items-center" id="navbarDropdown-menu" data-bs-toggle="dropdown" aria-expanded="false">
                        <img alt="menu" class="menu-icon"
                             :src="require('./../../assets/menu.png')">
                    </div>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown-menu">
                        <li class="nav-item">
                            <a class="dropdown-item" @click="gotoContacts">{{localizedData.contact}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="dropdown-item" @click="gotoPrivacy">{{privacy}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="dropdown-item" @click="gotoTerms">{{terms}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>

import portalData from './../../portal.json';

export default {
    name: 'NavBar',
    data() {
        return {
            screenWidth: 1000,
            portalData: portalData
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        setTimeout(function () {
            this.onResize();
        }.bind(this), 500);
    },
    unmounted() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        localizedData() {
            var result = {};
            Object.keys(this.portalData.header).forEach(propName => {
                var propValue = this.portalData.header[propName];
                if (typeof propValue === 'object' && propValue[this.lang]) {
                    result[propName] = propValue[this.lang];
                } else {
                    result[propName] = propValue;
                }
            });
            return result;
        },
        lang() {
            return this.$route.params.lang || "en";
        },
        languages() {
            return Object.keys(this.portalData.languages).map(l => {
                return {
                    code: l,
                    title: this.portalData.languages[l]
                }
            });
        },
        currentLanguageString() {
            var availableLanguages = this.portalData.languages;

            return Object.keys(availableLanguages)
                .filter(l => l === this.lang)
                .map(l => availableLanguages[l])[0];
        },
        privacy() {
            return this.portalData.footer.privacy[this.lang];
        },
        terms() {
            return this.portalData.footer.terms[this.lang];
        },
        isMobile() {
            return this.screenWidth <= 760;
        }
    },
    methods: {
        gotoGames() {
            this.$router.push({
                name: 'Games',
                params: {
                    category: 'games',
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined
                }
            });
        },
        gotoContacts() {
            this.$router.push({
                name: 'Contacts',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined
                }
            });
        },
        gotoPrivacy() {
            this.$router.push({
                name: 'Privacy',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined
                }});
        },
        gotoTerms() {
            this.$router.push({
                name: 'Terms',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined
                }});
        },
        setLanguage(langCode) {
            var routerData = {
                name: this.$route.name,
                params: {
                    lang: langCode === "en" ? undefined : langCode,
                    game: this.$route.params.game,
                    category: this.$route.params.category || 'games'
                }
            };
            this.$router.push(routerData);
        },
        isActive(route) {
            return route === this.$route.name;
        },
        isActiveLanguage(lang) {
            return lang === this.lang;
        },
        onChangeSearch(value) {
            this.$emit('onChangeSearch', value.target.value);
        },
        onResize() {
            this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }
    }
}
</script>

<style scoped>
.navbar-brand {
    height: 64px;
    margin-left: 30px;
    margin-right: 0.2rem;
    padding: 10px 0;
}

.navbar {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    box-shadow: 0 1px 5px 2px rgba(56, 118, 138, 0.2);
    font-size: 12px;
}

.nav-link {
    width: 100%;
    height: 100%;
}

.navbar-light .navbar-nav .nav-link {
    padding-right: 0.1rem;
    padding-left: 0.1rem;
}

.active-lang {
    font-weight: bold;
    cursor: default;
}

.nav-item:hover,
.dropdown:hover,
.navbar-brand:hover {
    cursor: pointer;
}

.navbar-item {
    padding-left: 20px;
    line-height: 36px;
}

.nav-icon {
    width: auto;
    height: 26px;
}

.search {
    background: rgb(235, 235, 235);
    border-radius: 20px;
    margin: 0 40px 0 60px;
}

.input-icon {
    margin: 0 15px;
}

.search-input, .search-input:focus {
    border: none;
    background: none;
    box-shadow: none;
    margin-left: 10px;
    color: #444444;
}

.search-icon {
    height: 22px;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.menu-icon {
    width: auto;
    height: 22px;
}

@media (max-width: 768px) {
    .search {
        height: 28px;
        margin: 0 0 0 10px;
        min-width: 66px;
    }

    .input-icon {
        margin: 0 10px;
    }

    .search-icon {
        margin-bottom: 10px;
        height: 18px;
    }

    .nav-icon {
        height: 20px;
    }

    .menu-icon {
        height: 16px;
    }

    .navbar-item {
        padding-left: 5px;
    }

    .navbar-brand {
        height: 50px;
        margin-left: 0;
    }
}
</style>
