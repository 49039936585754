<template>
    <NavBar/>
    <div class="navbar-space"/>

    <div class="container px-lg-5 text-black">
        <div class="container">
            <div class="row">
                <div class="game-icon-wrapper" :class="screenWidth <= 384 ? 'mb-3 d-flex justify-content-center' : 'col-2'">
                    <img class="game-icon" :src="getGameIcon()">
                </div>
                <div class="top-info-wrapper container" :class="screenWidth <= 384 ? 'col' : 'col-8'">
                    <div class="row">
                        <h1 class="display-7 fw-bold">{{localizedData.title_long}}</h1>
                    </div>
                    <div class="d-flex justify-content-start tagline">
                        <p class="fs-5">{{localizedData.tagline}}</p>
                        <div class="tag d-flex justify-content-start">
                            <img :alt="getGenreTitle()" class="tag-image" :title="getGenreTitle()" :src="getGenreImage()">
                            <p>{{getGenreTitle()}}</p>
                        </div>
                    </div>
                    <div class="play-button mb-2" :class="screenWidth <= 384 ? 'mx-auto' : ''"  @click="playGame()">
                        <p>{{localizedData.cta}}</p>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div>
            <ScreenshotsCarousel :has-max-width="false"/>
            <h1 class="fs-5 fw-bold mt-2" :class="screenWidth < 768 ? 'm-2' : ''">{{localizedData.description_title}}</h1>
            <div class="fs-6 m-2 text-justify" style="white-space: break-spaces;">{{localizedData.description_text_1}}</div>
            <h1 class="fs-5 fw-bold mt-4" :class="screenWidth < 768 ? 'm-2' : ''">{{localizedData.features_title}}</h1>
            <ul>
                <li class="fs-6 me-2 text-justify" v-for="feature in gameFeatures">{{feature}}</li>
            </ul>
            <div class="fs-6 m-2 text-justify">{{localizedData.description_text_2}}</div>
            <div class="platforms container d-flex justify-content-start align-items-end">
                <div v-for="storeName in baseStores" class="platform-tag d-flex justify-content-start align-items-center" @click="gotoStore(storeName)">
                    <img :alt="portalData.platforms[storeName]" class="platform-tag-image" :title="portalData.platforms[storeName]" :src="getPlatformImage(storeName)">
                    <p>{{portalData.platforms[storeName]}}</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="container" :class="screenWidth < 768 ? 'm-2' : ''">
            <h1 class="fs-5 fw-bold row">Game info</h1>
            <div class="row">
                <div :class="screenWidth > 768 ? 'col-3 ps-0' : 'row'">
                    <h2 class="fs-6 mt-3 fw-bold">{{requirementHeader()}}</h2>
                    <ul>
                        <li class="fs-6"><b>iOS:</b> {{requirement('iOs')}}</li>
                        <li class="fs-6"><b>MacOS:</b> {{requirement('MacOS')}}</li>
                        <li class="fs-6"><b>Android:</b> {{requirement('Android')}}</li>
                        <li class="fs-6"><b>Windows:</b> {{requirement('Windows OS')}}</li>
                    </ul>
                </div>
                <div v-if="this.otherStores.length !== 0" :class="screenWidth > 768 ? 'col-3' : 'row'">
                    <h2 class="fs-6 mt-3 fw-bold">{{availableHeader()}}</h2>
                    <div class="stores">
                        <div v-for="storeName in otherStores" class="store" :id="storeName" @click="gotoStore(storeName)" >
                            <img v-if="storeLink(storeName)" :src="getPlatformImage(storeName)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div :class="screenWidth < 768 ? 'm-2' : ''">
            <h1 class="fs-5 fw-bold">{{otherGames()}}</h1>
            <perfect-scrollbar class="horizontal-scroll mx-2">
               <div class="games">
                    <div v-for="gameName in gameNames" class="game-tile" @click="goToGame(gameName, $event)">
                        <img class="game-img" :src="gameImage(gameName)" :alt="gameTitle(gameName)">
                        <div class="game-name">{{gameTitle(gameName)}}</div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>

    <PageFooter/>
</template>

<script>
    import {computed} from 'vue';

    import portalData from './../../portal.json';

    import NavBar from "../components/navbar";
    import PageFooter from "../components/pagefooter";
    import ScreenshotsCarousel from "../components/screenshotscarousel";

    import YouTube from 'vue3-youtube'
    import {useHead} from '@vueuse/head';

export default {
    name: "Game",
    components: {
        ScreenshotsCarousel,
        NavBar,
        PageFooter,
        YouTube},
    data() {
        return {
            portalData: portalData,
            screenWidth: 1000
        }
    },
    beforeMount() {
        useHead(computed(() => this.head));
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(function () {
            this.onResize();
        }.bind(this), 500);
    },
    unmounted() {
        window.removeEventListener("resize", this.onResize);
        useHead({});
    },
    computed: {
        head() {
            if (!this.$route.params.game) {
                return {};
            }

            return {
                title: this.localizedData.title_long,
                meta: [
                    { name: 'description', content: this.localizedData.description_text_1},
                    { property: 'og:title', content: this.localizedData.title_long},
                    { property: 'og:site_name', content: 'Clever Apps Games'},
                    { property: 'og:description', content: this.localizedData.description_text_2},
                    { property: 'og:type', content: 'website'},
                    { property: 'og:url', content: 'https://cleverappssg.com/game/' + this.$route.params.game + '/' + this.lang + '/'},
                    { property: 'og:image', content: this.getGameBanner() },
                    { property: 'twitter:title', content: this.localizedData.title_long},
                    { property: 'twitter:description', content: this.localizedData.description_text_2},
                    { property: 'twitter:card', content: this.getGameBanner() }
                ],
                link: Object.keys(this.portalData.languages).map(lang => {
                    return {
                        rel: lang === this.lang ? "canonical" : "alternate",
                        hreflang: lang,
                        href: 'https://cleverappssg.com/game/' + this.$route.params.game + (lang === "en" ? '' : '/' + lang)
                    }
                })
            };
        },
        localizedData() {
            var data = this.portalData.games[this.$route.params.game];

            var result = {};
            Object.keys(data).forEach(propName => {
                var propValue = data[propName];
                if (typeof propValue === 'object' && propValue[this.lang]) {
                    result[propName] = propValue[this.lang];
                } else {
                    result[propName] = propValue;
                }
            });
            return result;
        },
        lang() {
            return this.$route.params.lang || "en";
        },
        isMobile() {
            return this.screenWidth <= 760;
        },
        baseStores() {
            return ["facebook", "google", "mac", "microsoft"].filter(store => this.storeLink(store) && Object.keys(this.portalData.platforms).includes(store));
        },
        ruStores() {
          return ["ok", "vk", "mm", "yandex", "rustore"];
        },
        otherStores() {
          var stores = Object.keys(this.portalData.platforms)
              .filter(store => this.storeLink(store) && !this.baseStores.includes(store))
              .filter(store => !["xsolla", "ios"].includes(store));

          if (this.lang !== "ru") {
            stores = stores.filter(store => !this.ruStores.includes(store));
          }
            return stores;
        },
        gameImageUrl() {
            return this.portalData.games[this.$route.params.game].banner;
        },
        gameVideoUrl() {
            return this.portalData.games[this.$route.params.game].video;
        },
        gameFeatures() {
            return this.portalData.games[this.$route.params.game].features_list[this.lang]
        },
        gameGenre() {
            return this.portalData.games[this.$route.params.game].category;
        },
        gameNames() {
            return Object.keys(this.portalData.games).filter(gameName => gameName !== this.$route.params.game);
        }
    },
    methods: {
        gotoStore(store) {
            this.$gtag.event('open_store_link', {
                game: this.$route.params.game,
                store: store
            });

            setTimeout(function () {
                window.location.href = this.storeLink(store);
            }.bind(this), 500);
        },
        storeLink(store) {
            return this.portalData.games[this.$route.params.game].links[store];
        },
        getStoreImage(store) {
            var images = require.context('./../../assets/stores', false, /\.png$/);
            return images('./' + store + '.png');
        },
        getPlatformImage(platform) {
            var images = require.context('./../../assets/platforms', false, /\.png$/);
            return images('./' + platform + '.png');
        },
        getGameBanner(wide) {
            var images = require.context('./../../assets/banners', false, /\.jpg$/);
            return images('./' + this.$route.params.game + (wide ? '_wide.jpg' : '.jpg'));
        },
        getGameIcon() {
            var images = require.context('./../../assets/banners', false, /\.jpg$/);
            return images('./' + this.$route.params.game + '_promo.jpg');
        },
        requirementHeader() {
            return this.portalData.requirements_header[this.lang];
        },
        availableHeader() {
            return this.portalData.available[this.lang];
        },
        otherGames() {
            return this.portalData.other_games[this.lang];
        },
        requirement(store) {
            return this.portalData.requirements[store][this.lang];
        },
        onResize() {
            this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            var videoContainer = document.getElementById("game-video-container");
            if (!videoContainer) {
                return;
            }

            var iframe = videoContainer.getElementsByTagName("iframe")[0];
            if (iframe) {
                iframe.style.width = "84vw";
                iframe.style.height = 84*540/960 + "vw";
                iframe.style.maxWidth = "960px";
                iframe.style.maxHeight = "540px";
                iframe.style.minWidth = "320px";
                iframe.style.minHeight = "180px";
            }
        },
        gameImage(game) {
            var images = require.context('./../../assets/banners', false, /\.jpg$/);
            return images('./' + game + '_promo.jpg');
        },
        gameTitle(game) {
            return this.portalData.games[game].title_short[this.lang];
        },
        goToGame(game, event) {
            var routerData = {
                name: 'Game',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined,
                    game: game
                }
            };

            if (event.ctrlKey) {
                window.open(this.$router.resolve(routerData).href, '_blank');
            } else {
                this.$router.push(routerData);
            }

            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        playGame() {
            window.location.href = this.$router.resolve({
                name: 'Play',
                params: {
                    lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined,
                    game: this.$route.params.game
                }
            }).href;
        },
        onVideoReady() {
            var videoHider = document.getElementById("video-hider");
            if (videoHider) {
                videoHider.style.height = 'auto';
            }
        },
        getGenreImage() {
            var images = require.context('./../../assets/genres', false, /\.png$/);
            return images('./' + this.gameGenre + '.png');
        },
        getGenreTitle() {
            return this.portalData.categories[this.gameGenre][this.lang];
        }
    }
}
</script>

<style scoped>

.navbar-space {
    height: 94px;
}

.game-icon-wrapper {
    min-width: 120px;
}

.game-icon {
    border-radius: 2em;
    width: 100%;
    max-width: 205px;
}

.top-info-wrapper {
    margin-left: 2em;
}

.tagline {
    line-height: 24px;
    margin-bottom: 16px;
}

.tagline > p {
    margin: 0;
}

.tag {
    border-radius: 30px;
    background: rgb(240, 240, 240);
    text-align: center;
    display: inline-block;
    font-size: 12px;
    color: rgb(71, 71, 71);
    padding: 0 10px;
    margin-left: 20px;
    height: 26px;
}

.tag > p {
    padding-top: 2px;
    margin: 0 8px 0 0;
}

.tag-image {
    height: 14px;
    margin: auto 8px;
}

.platforms {
    overflow: hidden;
}

.platform-tag {
    border-radius: 30px;
    background: rgb(240, 240, 240);
    text-align: center;
    display: inline-block;
    font-size: 14px;
    padding: 0 10px;
    margin-left: 20px;
}

.platform-tag:hover {
    cursor: pointer;
    background: rgb(220, 220, 220);
    transform: scale(1.05);
}

.platform-tag > p {
    padding-top: 2px;
    margin: 0 8px 0 0;
}

.platform-tag-image {
    height: 20px;
    margin: 6px 8px;
}

.covered {
    position:relative;
}

.stores {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}
.store {
    flex-basis: 25%;
}
.store img {
    margin: 4px;
    padding: 4px;
    height: 38px;
}
.store:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.store:active {
    opacity: 0.8;
}

.video {
    margin: 10px auto;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
}

.bg-blue {
    background-color: #c8f3c6;
}

.play-button {
    -webkit-border-radius: 10px;
    border-radius: 30px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    padding: 10px 0;
    max-width: 260px;
    min-width: 140px;
    background-color: #e50101;
}

.play-button:active {
    background-color: #a60101;
}

.play-button:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.play-button > p {
    margin: 0;
}

.horizontal-scroll {
    position: relative;
    overflow: hidden;
}

.games {
    display: flex;
    padding: 15px 0;
}

.game-tile {
    width: auto;
    min-width: 100px;
    min-height: 100px;
    max-width: 180px;
    margin-left: 0.8vw;
    margin-right: 0.8vw;
}

.game-img {
    width: 100%;
    height: auto;
    min-width: 80px;
    min-height: 80px;
    border-radius: 30px;
}

.game-name {
    text-align: center;
    margin-top: 6px;
    font-weight: 700;
    font-size: 14px;
    transition: opacity .2s ease,color .3s ease;
}

.game-tile:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.text-justify {
    text-align: justify!important;
}

@media (max-width: 768px) {
    .top-info-wrapper {
        margin-left: 0;
    }

    .tagline {
        flex-flow: column;
        align-items: flex-start;
    }

    .tag {
        margin-top: 5px;
        margin-left: 0;
    }

    .play-button {
        width: 100%;
    }

    .platforms {
        overflow-x: visible;
        white-space: nowrap;
        padding-bottom: 6px;
    }

    .platform-tag:first-child {
        margin-left: 0;
    }
}
</style>

